import { Layout } from 'components';
import { useApp, useAuth } from 'hooks';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Folders = lazy(() => import('containers/Folders'));
const Dashboard = lazy(() => import('containers/Dashboard'));
const Settings = lazy(() => import('containers/Settings'));

const App = () => {
  const { isLoggedIn } = useAuth();
  const { currentUser } = useApp();

  if (!isLoggedIn) {
    return <Navigate to="/connexion" />;
  }

  if (currentUser?.temporaryPassword) {
    return (
      <Navigate
        to={`/nouveau-mot-de-passe?state=firstLogin&userId=${currentUser?.id}`}
      />
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path="/tableau-de-bord/*" element={<Dashboard />} />
        <Route path="/dossiers/*" element={<Folders />} />
        <Route path="/parametres/*" element={<Settings />} />
        <Route path="*" element={<Navigate to="/dossiers" />} />
      </Routes>
    </Layout>
  );
};

export default App;
