import { memo } from 'react';

const Cog = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.25}
      transform="translate(2 2)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.788 2.032h0a1.632 1.632 0 002.424 0L12 1.167h0a1.631 1.631 0 012.842 1.177l-.06 1.167h0a1.631 1.631 0 001.71 1.711l1.166-.059h0a1.633 1.633 0 011.175 2.842l-.868.783h0a1.633 1.633 0 000 2.425l.868.783h0a1.631 1.631 0 01-1.177 2.842l-1.167-.06h0a1.632 1.632 0 00-1.713 1.714l.059 1.166h0A1.633 1.633 0 0112 18.833l-.784-.867h0a1.632 1.632 0 00-2.424 0l-.787.867h0a1.633 1.633 0 01-2.838-1.173l.06-1.167h0a1.632 1.632 0 00-1.714-1.713l-1.166.06h0A1.633 1.633 0 011.167 12l.867-.783h0a1.633 1.633 0 000-2.424l-.867-.788h0a1.632 1.632 0 011.174-2.838l1.166.059h0a1.633 1.633 0 001.716-1.717l-.056-1.167h0a1.633 1.633 0 012.837-1.175l.783.865z" />
      <circle cx={10} cy={10.001} r={3.75} />
    </g>
  </svg>
);

export default memo(Cog);
