import { memo } from 'react';

const CountdownDesc = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50002 15H7.00002V5.5C7.00002 5.22386 6.77617 5 6.50002 5H5.50002C5.22388 5 5.00002 5.22386 5.00002 5.5V15H3.50002C3.05659 15 2.83221 15.5387 3.14721 15.8534L5.64721 18.8534C5.84245 19.0485 6.15885 19.0485 6.35409 18.8534L8.85409 15.8534C9.16721 15.5394 8.9444 15 8.50002 15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0417 6.18727C16.8315 6.18727 14.2292 8.78962 14.2292 11.9998C14.2292 15.2099 16.8315 17.8123 20.0417 17.8123C20.4559 17.8123 20.7917 18.1481 20.7917 18.5623C20.7917 18.9765 20.4559 19.3123 20.0417 19.3123C16.0031 19.3123 12.7292 16.0384 12.7292 11.9998C12.7292 7.96119 16.0031 4.68727 20.0417 4.68727C20.4559 4.68727 20.7917 5.02306 20.7917 5.43727C20.7917 5.85148 20.4559 6.18727 20.0417 6.18727Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5417 10.6873C10.5417 10.2731 10.8775 9.93727 11.2917 9.93727H18.2917C18.7059 9.93727 19.0417 10.2731 19.0417 10.6873C19.0417 11.1015 18.7059 11.4373 18.2917 11.4373H11.2917C10.8775 11.4373 10.5417 11.1015 10.5417 10.6873Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5417 13.3123C10.5417 12.8981 10.8775 12.5623 11.2917 12.5623H16.5417C16.9559 12.5623 17.2917 12.8981 17.2917 13.3123C17.2917 13.7265 16.9559 14.0623 16.5417 14.0623H11.2917C10.8775 14.0623 10.5417 13.7265 10.5417 13.3123Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(CountdownDesc);
