import { CLIENT_ID, INTERNAL_API_BASE } from '../constants';
import { get, post } from './';

export const login = payload =>
  post(`/api/login`, {
    ...payload,
  });

export const refreshToken = () =>
  post(`/api/v1/oauth/token`, {
    refreshToken: localStorage.getItem('refreshToken'),
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });

export const forgotPassword = payload => post(`/api/forgot_password/`, payload);

export const verifyToken = () => get(`${INTERNAL_API_BASE}/v1/token_verify`);
