import styled from 'styled-components';

const Track = styled.div`
  border-radius: 999em;
  position: relative;
  height: 6px;
  width: 100%;
  background-color: ${({ theme }) => theme.separatorLight};
`;

const Progress = styled.div`
  border-radius: 999em;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.success};
`;

const ProgressBar = ({ value, total }) => (
  <Track>
    <Progress
      style={{
        width: value === 0 ? 0 : `${Math.min((value / total) * 100, 100)}%`,
      }}
    />
  </Track>
);

export default ProgressBar;
