import { memo } from 'react';

const DateDesc = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50002 15H6.00002V5.5C6.00002 5.22386 5.77617 5 5.50002 5H4.50002C4.22388 5 4.00002 5.22386 4.00002 5.5V15H2.50002C2.05659 15 1.83221 15.5387 2.14721 15.8534L4.64721 18.8534C4.84245 19.0485 5.15885 19.0485 5.35409 18.8534L7.85409 15.8534C8.16721 15.5394 7.9444 15 7.50002 15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6577 7.78102C10.3815 7.78102 10.1577 8.00488 10.1577 8.28102V16.3435C10.1577 16.6197 10.3815 16.8435 10.6577 16.8435H20.3452C20.6213 16.8435 20.8452 16.6197 20.8452 16.3435V8.28102C20.8452 8.00488 20.6213 7.78102 20.3452 7.78102H10.6577ZM8.65768 8.28102C8.65768 7.17645 9.55311 6.28102 10.6577 6.28102H20.3452C21.4498 6.28102 22.3452 7.17645 22.3452 8.28102V16.3435C22.3452 17.4481 21.4498 18.3435 20.3452 18.3435H10.6577C9.55311 18.3435 8.65768 17.4481 8.65768 16.3435V8.28102Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65768 10.281C8.65768 9.86681 8.99347 9.53102 9.40768 9.53102H21.5952C22.0094 9.53102 22.3452 9.86681 22.3452 10.281C22.3452 10.6952 22.0094 11.031 21.5952 11.031H9.40768C8.99347 11.031 8.65768 10.6952 8.65768 10.281Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6577 4.65602C13.0719 4.65602 13.4077 4.99181 13.4077 5.40602V8.24977C13.4077 8.66398 13.0719 8.99977 12.6577 8.99977C12.2435 8.99977 11.9077 8.66398 11.9077 8.24977V5.40602C11.9077 4.99181 12.2435 4.65602 12.6577 4.65602Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3452 4.65602C18.7594 4.65602 19.0952 4.99181 19.0952 5.40602V8.24977C19.0952 8.66398 18.7594 8.99977 18.3452 8.99977C17.931 8.99977 17.5952 8.66398 17.5952 8.24977V5.40602C17.5952 4.99181 17.931 4.65602 18.3452 4.65602Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(DateDesc);
