import { memo } from 'react';

const Comments = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.063 2.314a.749.749 0 00-.75.749v4.123c0 .415.335.75.749.75h2.75c.345 0 .625.28.625.625v2.618l3.06-3.058a.625.625 0 01.441-.183h11a.75.75 0 00.75-.75V3.064a.75.75 0 00-.75-.75H3.063zm-2 .749c0-1.104.895-1.999 2-1.999h17.875a2 2 0 012 2v4.124a2 2 0 01-2 2H10.197L6.254 13.13a.625.625 0 01-1.067-.442V9.186H3.062a1.999 1.999 0 01-1.999-2V3.063z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.688 11.312c0-.345.28-.625.625-.625h9.625a2 2 0 012 2.001v4.124a2 2 0 01-2 2h-2.125v3.5a.625.625 0 01-1.067.442l-3.943-3.942H3.062a2 2 0 01-2-1.999v-4.126a2 2 0 012.001-1.999.625.625 0 110 1.25.75.75 0 00-.751.749v4.126a.75.75 0 00.75.749h11c.166 0 .325.066.442.183l3.059 3.058v-2.616c0-.345.28-.625.625-.625h2.75a.75.75 0 00.75-.75v-4.124a.75.75 0 00-.75-.751h-9.625a.625.625 0 01-.625-.625z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Comments);
