import { get, post, put } from 'api';
import { INTERNAL_API_BASE } from '../constants';

export const postAttachments = payload =>
  post(`${INTERNAL_API_BASE}/v1/attachments`, payload);

export const postInvoice = payload =>
  post(`${INTERNAL_API_BASE}/v1/invoices`, payload);

export const postComment = payload =>
  post(`${INTERNAL_API_BASE}/v1/comments`, payload);

export const postDocument = ({ getcash_creance_id, ...payload }) =>
  post(
    `${INTERNAL_API_BASE}/v1/getcash_creances/${getcash_creance_id}/documents`,
    payload,
  );

// Folders creation flow

export const postFolderCsvFields = payload =>
  post(`${INTERNAL_API_BASE}/v1/creance_schemas/import_csv_fields`, payload);

export const putFolderSchema = payload =>
  put(`${INTERNAL_API_BASE}/v1/creance_schemas`, payload);

export const importFoldersCsv = payload =>
  post(`${INTERNAL_API_BASE}/v1/creance_schemas/import_csv`, payload);

export const postFolder = payload =>
  post(`${INTERNAL_API_BASE}/v1/creances`, payload);

export const getFoldersSchema = params =>
  get(`${INTERNAL_API_BASE}/v1/creance_schemas`, { params });

// Payments  creation flow

export const getPaymentsSchema = params =>
  get(`${INTERNAL_API_BASE}/v1/versement_schemas`, { params });

export const postPaymentsSchema = payload =>
  post(`${INTERNAL_API_BASE}/v1/versement_schemas`, payload);

export const putPaymentsSchema = payload =>
  put(`${INTERNAL_API_BASE}/v1/versement_schemas`, payload);

export const postPaymentsCsvFields = payload =>
  post(`${INTERNAL_API_BASE}/v1/versement_schemas/import_csv_fields`, payload);

export const postPayment = payload =>
  post(`${INTERNAL_API_BASE}/v1/versements`, payload);

export const importPaymentsCsv = payload =>
  post(`${INTERNAL_API_BASE}/v1/versement_schemas/import_csv`, payload);
