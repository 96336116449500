import { memo } from 'react';

const File = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <g
      transform="translate(-32 -8)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M53.75 16.25h0a1.5 1.5 0 00-1.5-1.5H42.5l-1.8-2.4h0a1.499 1.499 0 00-1.2-.6h-3.75 0a1.5 1.5 0 00-1.5 1.5s0 0 0 0v13.5h0a1.5 1.5 0 001.5 1.5h16.5a1.5 1.5 0 001.5-1.5v-10.5z"
        stroke="currentColor"
        strokeWidth={1.25}
      />
    </g>
  </svg>
);

export default memo(File);
