import { media } from '@tymate/margaret';
import MainSidebar from 'components/MainSidebar';
import styled, { createGlobalStyle } from 'styled-components';
import { TopBar } from 'ui/layout';
import Breadcrumb from './Breadcrumb';

const LayoutStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
  }
`;

const Content = styled.main`
  padding-top: ${({ theme }) => theme.topBar.height};

  ${media.tablet`
    padding-left: ${({ theme }) => theme.mainNav.width};
  `}
`;

const Layout = ({ children }) => {
  return (
    <>
      <LayoutStyles />
      <MainSidebar />
      <Content>
        <TopBar>
          <Breadcrumb />
        </TopBar>
        {children}
      </Content>
    </>
  );
};

export default Layout;
