import { memo } from 'react';

const DateAsc = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50001 8.99983H6.00001V18.5C6.00001 18.7761 5.77615 19 5.50001 19H4.50001C4.22387 19 4.00001 18.7761 4.00001 18.5V8.99983H2.50001C2.05657 8.99983 1.8322 8.46108 2.1472 8.14638L4.6472 5.14633C4.84243 4.95122 5.15884 4.95122 5.35407 5.14633L7.85407 8.14638C8.1672 8.46045 7.94438 8.99983 7.50001 8.99983Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6577 7.78102C10.3815 7.78102 10.1577 8.00488 10.1577 8.28102V16.3435C10.1577 16.6197 10.3815 16.8435 10.6577 16.8435H20.3452C20.6213 16.8435 20.8452 16.6197 20.8452 16.3435V8.28102C20.8452 8.00488 20.6213 7.78102 20.3452 7.78102H10.6577ZM8.65767 8.28102C8.65767 7.17645 9.5531 6.28102 10.6577 6.28102H20.3452C21.4497 6.28102 22.3452 7.17645 22.3452 8.28102V16.3435C22.3452 17.4481 21.4497 18.3435 20.3452 18.3435H10.6577C9.5531 18.3435 8.65767 17.4481 8.65767 16.3435V8.28102Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65767 10.281C8.65767 9.86681 8.99346 9.53102 9.40767 9.53102H21.5952C22.0094 9.53102 22.3452 9.86681 22.3452 10.281C22.3452 10.6952 22.0094 11.031 21.5952 11.031H9.40767C8.99346 11.031 8.65767 10.6952 8.65767 10.281Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6577 4.65602C13.0719 4.65602 13.4077 4.99181 13.4077 5.40602V8.24977C13.4077 8.66398 13.0719 8.99977 12.6577 8.99977C12.2435 8.99977 11.9077 8.66398 11.9077 8.24977V5.40602C11.9077 4.99181 12.2435 4.65602 12.6577 4.65602Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3452 4.65602C18.7594 4.65602 19.0952 4.99181 19.0952 5.40602V8.24977C19.0952 8.66398 18.7594 8.99977 18.3452 8.99977C17.931 8.99977 17.5952 8.66398 17.5952 8.24977V5.40602C17.5952 4.99181 17.931 4.65602 18.3452 4.65602Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(DateAsc);
