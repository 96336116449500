import { media, viewportSizes } from '@tymate/margaret';
import { toPairs } from 'lodash';
import { css } from 'styled-components';

const colors = {
  blue: '#233391',
  blueLight: '#0093ff',
  blueDark: '#324ad3',
  blueLightBackground: 'rgba(50, 74, 211, 0.12)',
  blueLightFlat: '#98a4e9',
  blueGradient: 'linear-gradient(to bottom, #324ad3, #233391)',
  blueGradientLight: 'linear-gradient(to bottom, #6C7DE0, #324ad3)',
  red: '#e0351b',
  green: '#29cc88',
  white: '#ffffff',
};

export const theme = {
  colors,
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighter: 'rgba(0, 0, 0, 0.38)',

  backgroundTag: 'rgba(0, 147, 255, 0.12)',
  primary: colors.blue,
  primaryDark: colors.blueDark,

  background: '#f4f4f4',
  backgroundDisabled: 'rgba(0, 0, 0, 0.12)',
  textDisabled: 'rgba(0, 0, 0, 0.38)',

  error: colors.red,
  success: colors.green,

  separator: 'rgba(0, 0, 0, 0.12)',
  separatorLight: 'rgba(0, 0, 0, 0.08)',

  topBar: { height: '72px' },

  button: {
    color: colors.blueDark,
    alignX: 'center',
    alignY: 'center',
    primary: {
      fontWeight: 600,
      borderRadius: '4px',
      borderRadiusSmall: '2px',
      borderRadiusLarge: '8px',
      borderRadiusPill: '99em',
      background: colors.blueGradient,
      backgroundHover: colors.blueGradientLight,
      paddingVertical: '8px',
      paddingHorizontal: '16px',
      color: '#ffffff',
      boxShadow: '0 1px 2px 0 rgba(50, 74, 211, 0.5)',
      boxShadowHover: '0 2px 4px 0 rgba(50, 74, 211, 0.5)',
      boxShadowDisabled: 'none',
    },
    primaryLarge: {
      fontWeight: 600,
      borderRadius: '4px',
      background: colors.blueGradient,
      backgroundHover: colors.blueGradientLight,
      paddingVertical: '16px',
      paddingHorizontal: '24px',
      color: '#ffffff',
      boxShadow: '0 1px 2px 0 rgba(50, 74, 211, 0.5)',
      boxShadowHover: '0 2px 4px 0 rgba(50, 74, 211, 0.5)',
      boxShadowDisabled: 'none',
    },
    simple: {
      fontWeight: 600,
      borderRadius: '4px',
      paddingVertical: '8px',
      paddingHorizontal: '16px',
      background: colors.white,
      color: 'rgba(0, 0, 0, 0.6)',
      boxShadow:
        'inset 0 0 0 1px rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',
      boxShadowHover:
        'inset 0 0 0 1px rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08)',
    },
  },
  mainNav: {
    width: '240px',
    maxWidth: '100%',
    background: {
      mobile: colors.blue,
      tablet: colors.blue,
    },
    boxShadow: `0 4px 4px rgba(0,0,0,0.05)`,
    position: 'left',
    triggerColor: {
      expanded: '#ffffff',
      collapsed: 'rgba(0, 0, 0, 0.87)',
    },
  },

  tabs: {
    tab: {
      background: 'transparent',
      padding: '8px 0',
      boxShadowActive: `inset 0 -3px 0 0 ${colors.blue}`,
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.6)',
      colorActive: 'rgba(0, 0, 0, 0.87)',
    },
  },

  fonts: {
    title:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },

  viewportSizes: {
    tablet: 768,
    medium: 1000,
    desktop: 1200,
  },

  fontStacks: {
    h1Mega: {
      sizeMinRem: 1.375,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
    },
    h1: {
      sizeMinRem: 1.25,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.25,
      tag: 'h2',
      fontWeight: 700,
    },
    h2: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.5,
      lineHeightMax: 1.3,
    },
    headline: {
      sizeMinRem: 1.1875,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.5,
      lineHeightMax: 1.3,
    },
    bodyLarge: {
      sizeMinRem: 1.0625,
      sizeMaxRem: 1.1875,
      lineHeightMin: 1.5,
      lineHeightMax: 1.3,
    },
    body: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.5,
      lineHeightMax: 1.3,
    },
    bodySmall: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
    },
    legend: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
      color: 'textLight',
      defaultTag: 'p',
    },
  },
};

const cssLock = ({
  valueUnit = '',
  minValue,
  maxValue,
  minViewportWidth = viewportSizes.tablet,
  maxViewportWidth = viewportSizes.desktop,
}) =>
  `calc((${minValue} * 1${valueUnit}) + (${maxValue} - ${minValue}) * (100vw - ${
    minViewportWidth / 16
  }em) / (${maxViewportWidth / 16} - ${minViewportWidth / 16}))`;

export const fontSize = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      font-size: ${sizeMinRem}rem;

      ${media.tablet`
        font-size: ${cssLock({
          valueUnit: 'rem',
          minValue: sizeMinRem,
          maxValue: sizeMaxRem,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
      `}
    `,
  }),
  {},
);

export const lineHeight = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      line-height: ${lineHeightMin}em;

      ${media.tablet`
        line-height: ${cssLock({
          valueUnit: 'em',
          minValue: lineHeightMin,
          maxValue: lineHeightMax,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
        line-height: ${lineHeightMax}em;
      `}
    `,
  }),
  {},
);
