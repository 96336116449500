import { memo } from 'react';

const IcDesc = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      d="M13.5 17h-2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm-4-2H8V5.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V15H4.5a.5.5 0 0 0-.353.853l2.5 3a.5.5 0 0 0 .707 0l2.5-3A.5.5 0 0 0 9.5 15Zm8-6h-6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm-2 4h-4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm4-8h-8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(IcDesc);
