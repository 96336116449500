import { memo } from 'react';

const IcBin = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.062 5.35c0-1.057.856-1.912 1.913-1.912h4.05c1.057 0 1.912.855 1.912 1.912v.788h4.838a.563.563 0 010 1.125h-2.137V18.85a1.912 1.912 0 01-1.913 1.912h-9.45a1.912 1.912 0 01-1.913-1.912V7.262H3.225a.563.563 0 010-1.124h4.837V5.35zm1.125.788h5.626V5.35a.787.787 0 00-.788-.787h-4.05a.787.787 0 00-.788.787v.788zm-2.7 1.125V18.85c0 .435.353.787.788.787h9.45a.787.787 0 00.787-.787V7.262H6.487zm3.488 2.925c.31 0 .563.251.563.562v5.4a.563.563 0 01-1.126 0v-5.4c0-.31.252-.563.563-.563zm4.05 0c.31 0 .563.251.563.562v5.4a.563.563 0 01-1.125 0v-5.4c0-.31.251-.563.562-.563z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcBin);
