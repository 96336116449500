import { Modal as MgtModal, Stack } from '@tymate/margaret';
import { isValidElement } from 'react';
import { Title } from 'ui';

const Modal = ({ title, ...props }) => (
  <MgtModal
    {...props}
    title={
      isValidElement(title) ? (
        title
      ) : (
        <Stack marginBottom={1}>
          <Title>{title}</Title>
        </Stack>
      )
    }
  />
);

export default Modal;
