import { List, MainNav, Stack, media } from '@tymate/margaret';
import { useBreakpoint, useRoutes } from 'hooks';
import { capitalize, last } from 'lodash';
import { useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { MdArrowDropDown, MdKeyboardArrowRight } from 'react-icons/md';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Dropdown from './Dropdown';

const shine = keyframes`
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 140px;
  }
`;

const Icon = styled.span`
  display: flex;
`;

const Hamburger = styled(Stack)`
  > button {
    padding: 0;
    height: auto;
    width: auto;
  }
`;

const Wrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
      ${media.tablet`
        display: none;
      `};
    `}
`;

const BreadcrumbContent = styled(List)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Crumb = styled.li`
  display: none;
  display: flex;
  line-height: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  > svg {
    display: none;
    ${media.tablet`
      display: flex;
      margin-left: ${({ theme }) => theme.spacing(0.25)};
      margin-right: ${({ theme }) => theme.spacing(0.25)};
    `}
  }
`;

const CrumbLink = styled(NavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  line-height: 1.5;
  display: none;

  ${({ isFirst }) =>
    isFirst &&
    css`
      font-weight: bold;
      ${({ theme }) => theme.fontStyles.headline}
    `}

  > svg {
    display: flex;
    margin-right: ${({ theme }) => theme.spacing(0.375)};
  }

  ${media.tablet`
    display: flex;
  `}
`;

const CrumbLast = styled(CrumbLink)`
  display: flex;
  ${({ variant }) =>
    variant !== 'main' &&
    css`
      box-shadow: none;
      color: ${({ theme }) => theme.textLight};
      padding-left: 0;
      padding-right: 0;
    `}
`;

const MobileTrigger = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.textLight};
`;

const Placeholder = styled.div`
  height: 14px;
  width: ${({ theme }) => theme.spacing(6)};
  background-image: linear-gradient(90deg, #e6e7e8, #f0f1f2 20px, #e6e7e8 40px);
  animation: ${shine} 2s linear infinite;
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
`;

const Breadcrumb = () => {
  const { pathname, key: locationKey } = useLocation();
  const breakpoint = useBreakpoint();
  const mobileBreadcrumbRef = useRef();
  const { ignoredPathsRegexes, pathsRegexesWithPlaceholder, routesNames } =
    useRoutes();
  const urlChunks = pathname.split('/').filter(Boolean);

  const getPathTranslation = path => {
    const chunks = path.split('/');
    const chunk = last(chunks);

    const routeCustomName = routesNames[`/${path}`];
    const chunkCustomName = routesNames[chunk];

    if (Boolean(routeCustomName)) {
      return routeCustomName;
    }

    if (Boolean(chunkCustomName)) {
      return chunkCustomName;
    }

    for (const regex of pathsRegexesWithPlaceholder) {
      if (Boolean(path.match(regex))) {
        return <Placeholder />;
      }
    }

    return capitalize(chunk);
  };

  const isIgnored = path => {
    for (const regex of ignoredPathsRegexes) {
      if (Boolean(path.match(regex))) {
        return true;
      }
    }

    return false;
  };

  const chunks = urlChunks.reduce((acc, _, index) => {
    const path = urlChunks.slice(0, index + 1).join('/');
    if (isIgnored(path)) {
      return acc;
    }

    return [
      ...acc,
      {
        path: `/${path}`,
        text: getPathTranslation(path),
      },
    ];
  }, []);

  useEffect(() => {
    if (!mobileBreadcrumbRef.current) {
      return;
    }

    mobileBreadcrumbRef.current.close();
  }, [locationKey]);

  const pageTitle =
    typeof last(chunks)?.text === 'object' || !Boolean(last(chunks)?.text)
      ? 'Cap Recouvrement'
      : `${last(chunks)?.text} – Cap Recouvrement`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Wrapper aria-label="Breadcrumb">
        {breakpoint === 'mobile' && (
          <Hamburger>
            <MainNav.Trigger style={{ padding: 0 }} />
          </Hamburger>
        )}

        {breakpoint === 'mobile' && chunks.length <= 1 && (
          <MobileTrigger>{last(chunks)?.text}</MobileTrigger>
        )}

        {breakpoint === 'mobile' && chunks.length > 1 && (
          <Dropdown
            ref={mobileBreadcrumbRef}
            trigger={
              <MobileTrigger>
                {last(chunks)?.text} <MdArrowDropDown size={24} />
              </MobileTrigger>
            }
          >
            {chunks.slice(0, -1).map(({ path, text, icon }, index) => (
              <Dropdown.MenuItem as={Link} to={path}>
                {Boolean(icon) && <Icon>{icon}</Icon>}
                <span>{text}</span>
              </Dropdown.MenuItem>
            ))}
          </Dropdown>
        )}

        {breakpoint !== 'mobile' && (
          <BreadcrumbContent>
            {chunks.map(({ path, text, icon }, index) => {
              const isLast = index === chunks.length - 1;
              const isFirst = index === 0;

              return (
                <Crumb isFirst={isFirst} isLast={isLast} key={index}>
                  {index !== 0 && <MdKeyboardArrowRight />}

                  {isLast ? (
                    <CrumbLast
                      isFirst={isFirst}
                      as="span"
                      variant={index === 0 && 'main'}
                    >
                      {icon}
                      {text}
                    </CrumbLast>
                  ) : (
                    <CrumbLink isFirst={isFirst} to={path}>
                      {icon}
                      {text}
                    </CrumbLink>
                  )}
                </Crumb>
              );
            })}
          </BreadcrumbContent>
        )}
      </Wrapper>
    </>
  );
};

export default Breadcrumb;
