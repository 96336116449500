import { memo } from 'react';

const ClientAsc = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50002 8.99983H7.00002V18.5C7.00002 18.7761 6.77617 19 6.50002 19H5.50002C5.22388 19 5.00002 18.7761 5.00002 18.5V8.99983H3.50002C3.05659 8.99983 2.83221 8.46108 3.14721 8.14638L5.64721 5.14633C5.84245 4.95122 6.15885 4.95122 6.35409 5.14633L8.85409 8.14638C9.16721 8.46045 8.9444 8.99983 8.50002 8.99983Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0309 6.18727C13.7538 6.18727 12.7184 7.22261 12.7184 8.49977C12.7184 9.77693 13.7538 10.8123 15.0309 10.8123C16.3081 10.8123 17.3434 9.77693 17.3434 8.49977C17.3434 7.22261 16.3081 6.18727 15.0309 6.18727ZM11.2184 8.49977C11.2184 6.39419 12.9253 4.68727 15.0309 4.68727C17.1365 4.68727 18.8434 6.39419 18.8434 8.49977C18.8434 10.6054 17.1365 12.3123 15.0309 12.3123C12.9253 12.3123 11.2184 10.6054 11.2184 8.49977Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0309 13.6248C12.304 13.6248 10.0934 15.8354 10.0934 18.5623C10.0934 18.9765 9.75763 19.3123 9.34341 19.3123C8.9292 19.3123 8.59341 18.9765 8.59341 18.5623C8.59341 15.0069 11.4756 12.1248 15.0309 12.1248C18.5862 12.1248 21.4684 15.0069 21.4684 18.5623C21.4684 18.9765 21.1326 19.3123 20.7184 19.3123C20.3042 19.3123 19.9684 18.9765 19.9684 18.5623C19.9684 15.8354 17.7578 13.6248 15.0309 13.6248Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ClientAsc);
