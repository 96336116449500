import { viewportSizes } from '@tymate/margaret';
import { createBreakpoint } from 'react-use';

const useBreakpoint = createBreakpoint({
  loading: 0,
  mobile: 1,
  ...viewportSizes,
});

export default useBreakpoint;
