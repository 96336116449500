import { Button as MgtButton } from '@tymate/margaret';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledButton = styled(MgtButton)`
  min-width: 6rem;
  min-height: 2.5rem;
  transition: background 150ms ease;
  text-align: inherit;
`;

const Button = props => (
  <StyledButton
    {...props}
    as={Boolean(props.as) ? props.as : Boolean(props.to) ? Link : undefined}
  />
);

export default Button;
