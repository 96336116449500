import { Stack } from '@tymate/margaret';
import { useRef } from 'react';
import styled from 'styled-components';

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  input {
    border: none;
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

const CheckboxBorder = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.separator};
  align-items: center;
  justify-content: center;
`;

const CheckboxContent = styled.div`
  height: 14px;
  width: 14px;

  ${({ isChecked, theme }) =>
    isChecked &&
    `
      background-color: ${theme.primary};
    `};

  ${({ isDisabled, isChecked, theme }) =>
    isDisabled &&
    isChecked &&
    `
      background-color: ${theme.disabled}; 
    `};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'auto')};
`;

export const CheckboxIcon = ({ isChecked, isDisabled }) => (
  <CheckboxBorder isChecked={isChecked}>
    <CheckboxContent isDisabled={isDisabled} isChecked={isChecked} />
  </CheckboxBorder>
);

const Checkbox = ({ checked, disabled, label, ...props }) => {
  const checkboxRef = useRef();

  return (
    <CheckboxWrapper>
      <input ref={checkboxRef} type="checkbox" checked={checked} {...props} />
      <Stack gutterSize={0.5}>
        <CheckboxIcon isChecked={checked} isDisabled={disabled} />
        <div>{label}</div>
      </Stack>
    </CheckboxWrapper>
  );
};

Checkbox.Icon = CheckboxIcon;

export default Checkbox;
