import { memo } from 'react';

const IcUsers = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.875 5.208a2.344 2.344 0 100 4.688 2.344 2.344 0 000-4.688zM3.489 7.552a3.386 3.386 0 116.772 0 3.386 3.386 0 01-6.772 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.875 11.98a4.167 4.167 0 00-4.167 4.167.521.521 0 01-1.042 0 5.21 5.21 0 0110.418-.001.521.521 0 11-1.042 0 4.166 4.166 0 00-4.167-4.166zM13.977 7.292a1.823 1.823 0 100 3.646 1.823 1.823 0 000-3.646zm-2.865 1.823a2.865 2.865 0 115.73 0 2.865 2.865 0 01-5.73 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.087 15a3.318 3.318 0 00-4.26-1.964.52.52 0 11-.359-.978 4.36 4.36 0 015.597 2.583c.177.48.269.99.269 1.505a.521.521 0 01-1.042 0c0-.39-.07-.778-.205-1.145"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcUsers);
