import { createContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const ToastsContext = createContext();

const ToastsProvider = ({ children }) => {
  const notify = (message, { kind, ...options }) => {
    if (kind === 'success') {
      toast.success(message, options);
      return;
    }

    if (kind === 'error') {
      toast.error(message, options);
      return;
    }

    toast(message, options);
  };

  return (
    <ToastsContext.Provider value={{ notify }}>
      {children}
      <Toaster
        position="bottom-center"
        toastOptions={{
          success: {
            duration: 5000,
          },
        }}
      />
    </ToastsContext.Provider>
  );
};

export default ToastsProvider;
