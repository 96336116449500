import { memo } from 'react';

const IcCalendar = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.877 5.75a.625.625 0 00-.625.625v13.75c0 .345.28.625.625.625h16.25c.345 0 .625-.28.625-.625V6.375a.625.625 0 00-.625-.625H3.877zm-1.875.625c0-1.036.84-1.875 1.875-1.875h16.25c1.036 0 1.875.84 1.875 1.875v13.75c0 1.035-.84 1.875-1.875 1.875H3.877a1.875 1.875 0 01-1.875-1.875V6.375z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.627 2c.345 0 .625.28.625.625V7a.625.625 0 11-1.25 0V2.625c0-.345.28-.625.625-.625zm8.75 0c.345 0 .625.28.625.625V7a.625.625 0 11-1.25 0V2.625c0-.345.28-.625.625-.625zM2.002 10.125c0-.345.28-.625.625-.625h18.75a.625.625 0 110 1.25H2.627a.625.625 0 01-.625-.625z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcCalendar);
