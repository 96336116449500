import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import { ErrorMessageWrapper, FormField, FormLabel } from 'ui/forms';
import SegmentedControl from './SegmentedControl';

const SegmentedControlField = ({
  name,
  label,
  rightLabel,
  retrieveSelectedValue,
  ...props
}) => {
  const [{ value }, { touched, error }, { setTouched, setValue }] = useField({
    name,
  });
  const hasError = Boolean(touched) && Boolean(error);

  const handleSelect = value => {
    setTimeout(() => setValue(value));
    setTimeout(() => setTouched(true));

    if (Boolean(retrieveSelectedValue)) {
      retrieveSelectedValue(value);
    }
  };

  return (
    <FormField>
      {(Boolean(label) || Boolean(rightLabel)) && (
        <Stack
          alignX="space-between"
          alignY="center"
          marginBottom={0.5}
          size="fit-content"
        >
          {label && <FormLabel as="span">{label}</FormLabel>}
          {rightLabel}
        </Stack>
      )}
      <Stack style={{ position: 'relative', width: 'fit-content' }}>
        <Stack>
          <SegmentedControl
            {...props}
            onSelect={handleSelect}
            value={value}
            size="full"
            hasError={hasError}
          />
        </Stack>
      </Stack>
      {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default SegmentedControlField;
