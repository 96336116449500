import { ButtonReset, Stack } from '@tymate/margaret';
import RawTextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

export const FormLabel = styled.label`
  ${({ theme }) => theme.fontStyles.bodySmall};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;
`;

export const FormInfo = styled.label`
  ${({ theme }) => theme.fontStyles.bodySmall};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.textLighter};
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const PasswordButton = styled(ButtonReset)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.spacing(0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textLight};
`;

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 100%;
  transition: border-color 100ms ease;
  background-color: #ffffff;

  &:not([disabled]):focus,
  &:not([disabled]):active {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme?.input?.disabledBackground};
    color: ${({ theme }) => theme?.input?.disabledColor};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active,
      &:not([disabled]):focus {
        border: 1px solid ${({ theme }) => theme.error};
      }
    `}

  ${({ withIcon, theme }) =>
    withIcon &&
    `
      padding-right: ${theme.spacing(2.25)}
    `}
`;

export const InputIcon = styled(Stack).attrs({
  alignX: 'center',
  alignY: 'center',
  color: 'textLight',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.spacing(0.75)};
`;

export const ErrorMessageWrapper = styled.div`
  ${({ theme }) => theme.fontStyles.bodySmall};

  color: ${({ theme }) => theme.error};
  position: absolute;
  top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => theme.fontStyles.bodySmall};
  color: ${({ theme }) => theme.error};
`;

export const TextArea = styled(RawTextareaAutosize)`
  flex: 1;
  width: 100%;
  min-height: 140px;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  transition: border-color 100ms ease;
  resize: none;

  &:not([disabled]):focus,
  &:not([disabled]):active,
  &:not([disabled]):hover {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.backgroundDisabled};
    color: ${({ theme }) => theme.textDisabled};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.danger};
      }
    `}
`;
