import { memo } from 'react';

const PositionAsc = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50002 8.99983H7.00002V18.5C7.00002 18.7761 6.77617 19 6.50002 19H5.50002C5.22388 19 5.00002 18.7761 5.00002 18.5V8.99983H3.50002C3.05659 8.99983 2.83221 8.46108 3.14721 8.14638L5.64721 5.14633C5.84245 4.95122 6.15885 4.95122 6.35409 5.14633L8.85409 8.14638C9.16721 8.46045 8.9444 8.99983 8.50002 8.99983Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.85 10C10.85 9.58579 11.1858 9.25 11.6 9.25H22C22.4142 9.25 22.75 9.58579 22.75 10C22.75 10.4142 22.4142 10.75 22 10.75H11.6C11.1858 10.75 10.85 10.4142 10.85 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 14.8C9.25 14.3858 9.58579 14.05 10 14.05H20.4C20.8142 14.05 21.15 14.3858 21.15 14.8C21.15 15.2142 20.8142 15.55 20.4 15.55H10C9.58579 15.55 9.25 15.2142 9.25 14.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2372 5.28849C16.6301 5.41948 16.8425 5.84422 16.7115 6.23718L12.7115 18.2372C12.5805 18.6301 12.1558 18.8425 11.7628 18.7115C11.3699 18.5805 11.1575 18.1558 11.2885 17.7628L15.2885 5.76283C15.4195 5.36988 15.8442 5.15751 16.2372 5.28849Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2372 5.28849C20.6301 5.41948 20.8425 5.84422 20.7115 6.23718L16.7115 18.2372C16.5805 18.6301 16.1558 18.8425 15.7628 18.7115C15.3699 18.5805 15.1575 18.1558 15.2885 17.7628L19.2885 5.76283C19.4195 5.36988 19.8442 5.15751 20.2372 5.28849Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(PositionAsc);
