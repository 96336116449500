import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import {
  ErrorMessageWrapper,
  FormField,
  FormInfo,
  FormLabel,
  TextArea,
} from 'ui/forms';

const TextAreaField = ({ label, optionnal = false, ...props }) => {
  const [field, meta] = useField(props);

  const hasError = Boolean(meta.touched) && Boolean(meta.error);
  return (
    <FormField>
      <Stack alignX="space-between">
        <FormLabel>{label}</FormLabel>
        {optionnal && <FormInfo>Optionnel</FormInfo>}
      </Stack>
      <TextArea {...props} {...field} hasError={hasError} />
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default TextAreaField;
