import { Button } from '@tymate/margaret';
import AuthLayout from 'components/AuthLayout';
import { TextField } from 'components/Fields';
import { Form, Formik } from 'formik';
import { Legend, Link, Title } from 'ui';

const ForgottenPassword = () => {
  return (
    <AuthLayout>
      <Link to="/connexion" style={{ fontSize: '16px' }}>
        Retour à la connexion
      </Link>
      <Title marginBottom={2} marginTop={1.5}>
        J'ai oublié mon mot de passe
      </Title>
      <Legend marginBottom={2}>
        Pas de panique, indiquez l’adresse email de votre compte et nous
        enverrons un lien de réinitilisation de mot de passe à cette adresse.
      </Legend>
      <Formik initialValues={{ username: '' }}>
        {props => (
          <Form>
            <TextField name="username" label="Email du compte" />
            <Button variant="primaryLarge" size="full">
              Réinitialiser mon mot de passe
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ForgottenPassword;
