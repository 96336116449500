import { AppContext } from 'containers/AppProvider';
import { AuthContext } from 'containers/AuthProvider';
import { RoutesContext } from 'containers/RoutesProvider';
import { ToastsContext } from 'containers/ToastsProvider';
import { useContext } from 'react';

export const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useApp must be used within an AppProvider`);
  }
  return context;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
};

export const useToasts = () => {
  const context = useContext(ToastsContext);

  if (context === undefined) {
    throw new Error(`useToasts must be used within an ToastsContext`);
  }
  return context;
};

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  if (context === undefined) {
    throw new Error(`useRoutes must be used within a RoutesProvider`);
  }

  return context;
};
