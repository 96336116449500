import { media } from '@tymate/margaret';
import background from 'images/auth-background.png';
import logo from 'images/logo.svg';
import styled from 'styled-components';

const Main = styled.div`
  display: grid;
  min-height: 100vh;
  ${media.tablet`
    grid-template-columns: 420px auto;
  `}
`;

const Background = styled.div`
  display: none;

  ${media.tablet`
    background-image: url(${background});
    background-position: center center;
    background-size: cover;
    display: block;
  `}
`;

const Content = styled.div`
  box-shadow: 2px 0 4px 0 ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(4)}
    ${({ theme }) => theme.spacing(2.5)};
  z-index: 2;
`;

const Logo = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(3.5)};
`;

const AuthLayout = ({ children }) => (
  <Main>
    <Content>
      <Logo>
        <img src={logo} alt="CAP recouvrement" />
      </Logo>
      {children}
    </Content>
    <Background />
  </Main>
);

export default AuthLayout;
