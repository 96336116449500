import { memo } from 'react';

const IcAddCircle = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6.354c.288 0 .521.233.521.521v2.604h2.604a.521.521 0 010 1.042h-2.604v2.604a.521.521 0 01-1.042 0v-2.604H6.875a.521.521 0 010-1.042h2.604V6.875c0-.288.233-.521.521-.521z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.708a7.292 7.292 0 100 14.584 7.292 7.292 0 000-14.584zM1.666 10a8.334 8.334 0 1116.668 0 8.334 8.334 0 01-16.668 0z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcAddCircle);
