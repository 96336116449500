import { createContext, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const RoutesContext = createContext();

const pathsNames = {
  '/': 'Tableau de bord',
  '/dossiers': 'Dossiers',
  '/dossiers/nouveau': 'Nouveau dossier',
  '/dossiers/nouveau/btob': 'Créance commerciale',
  '/dossiers/nouveau/btoc': 'Créance civile',
  '/details': 'Détail',
  '/mon-entreprise': 'Mon entreprise',
  '/parametres': 'Paramètres',
  '/support': 'Support',
  '/tableau-de-bord': 'Tableau de bord',
  '/parametres/automatisation/schema-de-creance': 'Schéma de créance',
  '/parametres/automatisation/schema-de-versements': 'Schéma de versements',

  termines: 'Terminés',
};

const RoutesProvider = ({ children }) => {
  const [routesNames, setRoutesNames] = useState(pathsNames);
  const { pathname } = useLocation();

  const ignoredPathsRegexes = [];
  const secondaryBreadcrumbIgnoredPathsRegexes = [];
  const pathsRegexesWithPlaceholder = [/dossiers(\/[^/]+){1}$/];

  const stringifiedRoutesNames = JSON.stringify(routesNames);

  const addRouteName = useCallback(
    ({ path, name }) => setRoutesNames({ ...routesNames, [path]: name }),
    // eslint-disable-next-line
    [stringifiedRoutesNames],
  );

  const addCurrentRouteName = useCallback(
    name => addRouteName({ path: pathname, name }),
    [addRouteName, pathname],
  );

  return (
    <RoutesContext.Provider
      value={{
        routesNames,
        addRouteName,
        addCurrentRouteName,
        ignoredPathsRegexes,
        secondaryBreadcrumbIgnoredPathsRegexes,
        pathsRegexesWithPlaceholder,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export default RoutesProvider;
