import Checkbox from 'components/Fields/Checkbox';
import { useField } from 'formik';
import { ErrorMessageWrapper, FormField } from 'ui/forms';

const CheckboxField = ({ name, ...props }) => {
  const [field, meta] = useField({ name });
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField>
      <Checkbox {...props} {...field} checked={field?.value} />
      {hasError && <ErrorMessageWrapper>{meta?.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default CheckboxField;
