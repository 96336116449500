import { SegmentedControls as MgtSegmentedControl } from '@tymate/margaret';
import styled, { css } from 'styled-components';

const SegmentedControl = styled(MgtSegmentedControl)`
  ${({ theme }) => theme.fontStyles.bodySmall};

  .active,
  .active:hover {
    background: ${({ theme }) => theme.colors.blueLightBackground};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blueLightFlat};
  }

  > li:first-child > button {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  > li:last-child > button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.error};
      > li {
        box-shadow: inset -1px 0 0 0 ${({ theme }) => theme.error};
      }
    `}
`;

export default SegmentedControl;
