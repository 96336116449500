import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { orderBy, round, sum } from 'lodash';
import Papa from 'papaparse';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_NOT_FOUND: 'Cette adresse mail n’est pas enregistrée',
  INVALID_GRANT:
    'L’adresse mail ou le mot de passe que vous avez entrés sont invalides',
  INVALID_FORMAT: 'Le montant ou la date est indiqué sous un mauvais format',
};

export const formatDate = (date, frmt) => {
  try {
    return format(new Date(date), frmt, { locale: fr });
  } catch (err) {
    return null;
  }
};

export const pluralize = (input, count) =>
  input
    .split(' ')
    .map(i => `${i}${count > 1 ? 's' : ''}`)
    .join(' ');

export const formatAmount = amount => {
  if (isNaN(amount)) {
    amount = 0;
  }

  if (typeof Intl === 'undefined') {
    return `${amount.toFixed(2)}\u00a0€`;
  }

  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};

export const parseAmountFieldValue = value =>
  Number((value || '').replace(/ /g, '').replace(',', '.'));

export const formatFolderTitle = ({ debiteurs }) =>
  debiteurs?.[0]?.raisonSociale ||
  debiteurs?.[0]?.nom + ' ' + debiteurs?.[0]?.prenom;

export const formatFolderTotalPaid = ({ versements }) =>
  round(sum((versements || []).map(({ montant }) => parseFloat(montant))), 2);

export const formatInvoicesTotal = (invoices = []) =>
  invoices.reduce(
    (total, { kind, montant }) =>
      total +
      Number(parseAmountFieldValue(montant) * (kind === 'AVOIR' ? -1 : 1)),
    0,
  );

export const fileToText = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject();
    reader.readAsText(file);
  });

export const parseCsv = async file => {
  const csvString = await fileToText(file);
  const parsedCsv = Papa.parse(csvString, { header: true });
  const { data, meta } = parsedCsv;
  const fields = meta?.fields || [];
  return { data, fields };
};

export const getSampleValues = ({ data, name, values }) => {
  const column = values?.[name];

  if (!Boolean(column)) {
    return '';
  }

  return orderBy(
    data.map(dataChunk => dataChunk?.[column]),
    o => (Boolean(o) ? -1 : 1),
  )
    .slice(0, 3)
    .join(' ; ');
};

export const formDataCSV = (csv, clientUuid) => {
  const formData = new FormData();
  formData.append('file', csv, csv.name);
  formData.append('client_uuid', clientUuid);
  return formData;
};
