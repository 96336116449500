import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DropdownMenuContent = styled(DropdownMenu.Content)`
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  max-height: var(--radix-dropdown-menu-content-available-height);
  overflow-y: auto;

  &[data-state='open'] {
    animation-name: ${slideUpAndFade};
  }
`;

const Dropdown = ({ trigger, children, align, ...props }) => {
  const location = useLocation();
  const key = location?.state?.key;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [key]);

  return (
    <DropdownMenu.Root
      open={props.isOpen || isOpen}
      onOpenChange={value => {
        if (props.onOpenChange) {
          props.onOpenChange(value);
        } else {
          setIsOpen(value);
        }
      }}
      modal={false}
    >
      <DropdownMenu.Trigger asChild>
        <div style={{ cursor: 'pointer' }}>{trigger}</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent sideOffset={6} align={align} collisionPadding={16}>
          {children}
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const menuItemStyles = css`
  all: unset;
  font-size: 1em;
  line-height: 1;
  color: ${({ theme }) => theme.textLight};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &[data-disabled] {
    color: ${({ theme }) => theme.themeLight};
    pointer-events: none;
  }

  &[data-highlighted],
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueLightBackground};
    color: ${({ theme }) => theme.primary};
  }

  &[data-state='checked'] {
    color: ${({ theme }) => theme.primary};
  }

  svg {
    color: ${({ theme }) => theme.primary};
  }
`;

Dropdown.MenuItem = styled(DropdownMenu.Item)`
  ${menuItemStyles}
`;

Dropdown.CheckboxItem = styled(DropdownMenu.CheckboxItem)`
  ${menuItemStyles}
`;

export default Dropdown;
