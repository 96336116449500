import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@tymate/margaret';
import { getClients } from 'api/clients';
import { getMe } from 'api/user';
import { useAuth, useToasts } from 'hooks';
import { createContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { logout, accessToken } = useAuth();
  const { notify } = useToasts();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['clients', accessToken],
    queryFn: () => getClients(),
    enabled: Boolean(accessToken),
  });
  const { data: userData, isLoading: isLoadingUser } = useQuery({
    queryKey: ['me', accessToken],
    queryFn: () => getMe(),
    enabled: Boolean(accessToken),
  });
  const clients = data?.data?.['hydra:member'] || [];
  const availableClients = clients.filter(({ blocage }) => blocage !== true);

  useDeepCompareEffect(() => {
    if (clients.length > 0 && availableClients.length === 0) {
      notify('Tous les clients de ce compte sont bloqués.', { kind: 'error' });
      logout();
    }
  }, [{ availableClients }]);

  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/connexion" />;
  }

  if (!Boolean(data) && isLoading) {
    return <Spinner />;
  }

  return (
    <AppContext.Provider
      value={{
        clients: availableClients,
        refreshClients: refetch,
        currentUser: userData?.data,
        isLoadingUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
