import { Button, Stack } from '@tymate/margaret';
import { login, verifyToken } from 'api/auth';
import AuthLayout from 'components/AuthLayout';
import { TextField } from 'components/Fields';
import { Form, Formik } from 'formik';
import { useAuth } from 'hooks';
import { Subtitle, Title } from 'ui';
import { ErrorMessage } from 'ui/forms';
import { ERRORS } from 'utils';
import * as Yup from 'yup';

const Auth = () => {
  const { onReceiveToken, logout } = useAuth();

  const handleSubmit = async (values, { setStatus }) => {
    try {
      const { data } = await login(values);
      onReceiveToken(data);
      const { status } = await verifyToken();
      if (status !== 204) {
        logout();
      }
    } catch (err) {
      const error = err?.response?.data?.message;
      if (error === 'Invalid credentials.') {
        setStatus(ERRORS.INVALID_GRANT);
      }
    }
  };

  return (
    <AuthLayout>
      <Title as="h1" marginBottom={0.5}>
        Connexion à la plateforme
      </Title>
      <Subtitle marginBottom={1.5}>Bienvenue !</Subtitle>
      <Formik
        validationSchema={Yup.object().shape({
          username: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
          password: Yup.string().required(ERRORS.REQUIRED),
        })}
        initialValues={{ username: '', password: '' }}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <Stack direction="column" gutterSize={2}>
              <TextField
                name="username"
                label="Email"
                autocomplete="username"
              />
              <TextField
                name="password"
                label="Mot de passe"
                type="password"
                autocomplete="password"
              />
              <Button
                variant="primaryLarge"
                size="full"
                isLoading={isSubmitting}
              >
                Se connecter
              </Button>
              {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default Auth;
