import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import paymentIcon from 'images/paiement-icon.svg';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { MdError } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';
import {
  ErrorMessageWrapper,
  FormField,
  FormLabel,
  Input,
  InputIcon,
} from 'ui/forms';

const AmountField = ({ label, rightLabel, inputIcon, ...props }) => {
  const [field, { touched, error }] = useField(props);
  const [id] = useState(uniqueId());
  const theme = useTheme();

  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField>
      {(Boolean(label) || Boolean(rightLabel)) && (
        <Stack alignX="space-between" alignY="center">
          {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
          {rightLabel}
        </Stack>
      )}
      <Stack style={{ position: 'relative' }} alignX="stretch" size="full">
        <Input
          id={id}
          as={NumberFormat}
          {...field}
          {...props}
          thousandSeparator=" "
          decimalSeparator=","
          decimalScale={2}
          hasError={hasError}
        />

        {hasError ? (
          <InputIcon>
            <MdError color={theme.error} size={14} />
          </InputIcon>
        ) : inputIcon ? (
          <InputIcon>{inputIcon}</InputIcon>
        ) : (
          <InputIcon>
            <img src={paymentIcon} alt="" />
          </InputIcon>
        )}
        {hasError ? <ErrorMessageWrapper>{error}</ErrorMessageWrapper> : null}
      </Stack>
    </FormField>
  );
};

export default AmountField;
