import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MargaretProvider, injectMargaret } from '@tymate/margaret';
import AppProvider from 'containers/AppProvider';
import { Auth, ForgottenPassword } from 'containers/Auth';
import NewPassword from 'containers/Auth/NewPassword';
import AuthProvider from 'containers/AuthProvider';
import RoutesProvider from 'containers/RoutesProvider';
import ToastsProvider from 'containers/ToastsProvider';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import { createGlobalStyle } from 'styled-components';
import { theme } from 'ui';
import App from './App';
import { ENVIRONMENT, SENTRY_DSN } from './constants';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: 'cap-recouvrement@' + process.env.npm_package_version,
  tracesSampleRate: 0.25,
  environment: ENVIRONMENT,
});

const GlobalStyles = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.text};
    font-family: ${({ theme }) => theme.fonts.body};
    ${({ theme }) => theme.fontStyles.body};
  }

  h1 {
    ${({ theme }) => theme.fontStyles.h1}
  }

  h2 {
    ${({ theme }) => theme.fontStyles.h2}
  }

  h3 {
    ${({ theme }) => theme.fontStyles.headline}
  }

  .ReactModal__Overlay {
    z-index: 9998 !important;
  }

  [data-radix-popper-content-wrapper] {
    z-index: 9999 !important;
  }
`;

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div />}>
      <Sentry.ErrorBoundary fallback={<div>Une erreur est survenue</div>}>
        <MargaretProvider theme={injectMargaret(theme)}>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <ToastsProvider>
              <BrowserRouter>
                <RoutesProvider>
                  <AuthProvider>
                    <Routes>
                      <Route path="connexion" element={<Auth />} />
                      <Route
                        path="nouveau-mot-de-passe"
                        element={<NewPassword />}
                      />
                      <Route
                        path="mot-de-passe-oublie"
                        element={<ForgottenPassword />}
                      />
                      <Route
                        path="*"
                        element={
                          <AppProvider>
                            <App />
                          </AppProvider>
                        }
                      />
                    </Routes>
                  </AuthProvider>
                </RoutesProvider>
              </BrowserRouter>
            </ToastsProvider>
          </QueryClientProvider>
        </MargaretProvider>
      </Sentry.ErrorBoundary>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();

reportWebVitals();
