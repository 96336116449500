import { isValidBIC, isValidIBAN } from 'ibantools';
import * as Yup from 'yup';
import { ERRORS } from './index';

export const getStepOneValidation = ({ kind }) => ({
  refClient: Yup.string().required(ERRORS.REQUIRED),
  debiteurs: Yup.array().of(
    Yup.object().shape(
      {
        siret:
          kind === 'btob'
            ? Yup.string()
                .matches(/^(\d{9}|\d{14})$/, ERRORS.INVALID)
                .required(ERRORS.REQUIRED)
            : Yup.mixed(),
        raisonSociale:
          kind === 'btob'
            ? Yup.string().required(ERRORS.REQUIRED)
            : Yup.mixed(),
        nom:
          kind === 'btoc'
            ? Yup.string().required(ERRORS.REQUIRED)
            : Yup.string(),
        prenom:
          kind === 'btoc'
            ? Yup.string().required(ERRORS.REQUIRED)
            : Yup.string(),
        addresse: Yup.string().required(ERRORS.REQUIRED),
        ville: Yup.string().required(ERRORS.REQUIRED),
        codePostal: Yup.string().required(ERRORS.REQUIRED),
        gsm: Yup.string().when('tel', {
          is: value => !Boolean(value),
          then: Yup.string().required(ERRORS.REQUIRED),
          otherwise: Yup.string(),
        }),
        tel: Yup.string().when('gsm', {
          is: value => !Boolean(value),
          then: Yup.string().required(ERRORS.REQUIRED),
          otherwise: Yup.string(),
        }),
        email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
        dateNaissance: kind === 'btoc' ? Yup.mixed() : Yup.string(),
        lieueNaissance: Yup.string(),
      },
      [['gsm', 'tel']],
    ),
  ),
});

export const getStepTwoValidation = () => ({
  debiteurs: Yup.array().of(
    Yup.object().shape({
      iban1: Yup.string().test(
        'isIban',
        ERRORS.INVALID,
        value => !Boolean(value) || isValidIBAN(value),
      ),
      bic1: Yup.string(
        'isBic',
        ERRORS.INVALID,
        value => !Boolean(value) || isValidBIC(value),
      ),
    }),
  ),
});

export const getStepThreeValidation = () => ({
  factures: Yup.array().of(
    Yup.object().shape({
      kind: Yup.string().required(ERRORS.REQUIRED),
      libelle: Yup.string().required(ERRORS.REQUIRED),
      montant: Yup.string().required(ERRORS.REQUIRED),
      date: Yup.string().required(ERRORS.REQUIRED),
      dateEchue: Yup.string(),
    }),
  ),
  isInvoicesValidated: Yup.bool().oneOf([true], ERRORS.REQUIRED),
});

export const getStepFourValidation = () => ({
  frais: Yup.array().of(
    Yup.object().shape({
      libelle: Yup.string().required(ERRORS.REQUIRED),
    }),
  ),
});

export const getStepHasError = ({ step, errors }) => {
  switch (step) {
    case 1:
      return (
        Boolean(errors?.refClient) ||
        Boolean(errors?.debiteurs?.[0]?.siret) ||
        Boolean(errors?.debiteurs?.[0]?.raisonSociale) ||
        Boolean(errors?.debiteurs?.[0]?.nom) ||
        Boolean(errors?.debiteurs?.[0]?.prenom) ||
        Boolean(errors?.debiteurs?.[0]?.addresse) ||
        Boolean(errors?.debiteurs?.[0]?.ville) ||
        Boolean(errors?.debiteurs?.[0]?.codePostal) ||
        Boolean(errors?.debiteurs?.[0]?.gsm) ||
        Boolean(errors?.debiteurs?.[0]?.tel) ||
        Boolean(errors?.debiteurs?.[0]?.email) ||
        Boolean(errors?.debiteurs?.[0]?.dateNaissance) ||
        Boolean(errors?.debiteurs?.[0]?.lieueNaissance)
      );
    case 2:
      return (
        Boolean(errors?.debiteurs?.[0]?.iban1) ||
        Boolean(errors?.debiteurs?.[0]?.bic1)
      );
    case 3:
      return Boolean(errors?.factures) || Boolean(errors?.isInvoicesValidated);
    case 4:
      return Boolean(errors?.frais);
    default:
      return undefined;
  }
};
