import { ButtonReset, Stack } from '@tymate/margaret';
import { PageHeader } from 'components';
import { useFormikContext } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars';
import styled, { css } from 'styled-components';
import { getStepHasError } from 'utils/validation';

const StepsWrapper = styled(Scrollbars)`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-left: auto;
  margin-right: auto;
  width: min-content !important;
  max-width: 100%;
`;

const Step = styled(ButtonReset)`
  text-align: left;
  white-space: nowrap;

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      color: ${({ theme }) => theme.primary};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${({ theme }) => theme.error};
    `}

  &[disabled] {
    cursor: default;
    color: ${({ theme }) => theme.textLighter};
  }
`;

const StepNumber = styled(Stack).attrs({ alignX: 'center', alignY: 'center' })`
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 999em;
  border: 1px solid currentColor;

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      background-color: #ffffff;
      border-color: ${({ theme }) => theme.primary};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: #ffffff;
      border-color: ${({ theme }) => theme.primary};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      background-color: ${({ theme }) => theme.error};
      color: #ffffff;
      border-color: ${({ theme }) => theme.error};
    `}
`;

const Steps = ({ title, steps, activeStep, onUpdateStep }) => {
  const { errors } = useFormikContext();

  return (
    <PageHeader paddingVertical={2} containerSize="main">
      <PageHeader.Title variant="centered">{title}</PageHeader.Title>

      <StepsWrapper autoHeight>
        <Stack direction="row" gutterSize={1}>
          {steps.map((step, index) => {
            const isActive = activeStep === index + 1;
            const isCompleted = step.isCompleted;
            const hasError =
              activeStep > index + 1 &&
              getStepHasError({ errors, step: index + 1 });

            return (
              <Step
                key={index}
                onClick={() => onUpdateStep(index + 1)}
                isActive={isActive}
                isCompleted={isCompleted}
                hasError={hasError}
                disabled={
                  index + 1 > activeStep && !steps?.[index - 1]?.isCompleted
                }
              >
                <Stack direction="row" gutterSize={0.5} alignY="center">
                  <StepNumber
                    isActive={isActive}
                    isCompleted={isCompleted}
                    hasError={hasError}
                  >
                    {index + 1}
                  </StepNumber>
                  <p>{step.title}</p>
                </Stack>
              </Step>
            );
          })}
        </Stack>
      </StepsWrapper>
    </PageHeader>
  );
};

export default Steps;
