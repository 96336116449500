import { Container, Stack } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { Title } from 'ui';

const PageHeaderContent = styled(Stack).attrs({
  size: 'full',
  alignX: 'space-between',
  alignY: 'center',
  gutterSize: 1,
  paddingVertical: 1.5,
  direction: { default: 'column', tablet: 'row' },
})``;

const PageHeaderTitle = styled(Title)`
  ${({ variant }) =>
    variant === 'centered' &&
    css`
      ${({ theme }) => theme.fontStyles.h1Mega};
      text-align: center;
    `}
`;

const PageHeaderAction = styled(Stack)``;

const PageHeaderBase = styled(Stack).attrs({
  direction: 'column',
})`
  background-color: #fff;
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.separator};
`;

const PageHeader = ({ children, containerSize = 'narrow', ...props }) => (
  <PageHeaderBase {...props}>
    <Container size={containerSize}>{children}</Container>
  </PageHeaderBase>
);

PageHeader.Content = PageHeaderContent;
PageHeader.Title = PageHeaderTitle;
PageHeader.Action = PageHeaderAction;

export default PageHeader;
