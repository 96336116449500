import { useQueryClient } from '@tanstack/react-query';
import { Button, Stack } from '@tymate/margaret';
import { updatePassword } from 'api/user';
import AuthLayout from 'components/AuthLayout';
import { TextField } from 'components/Fields';
import IcCheck from 'components/Icons/IcCheck';
import IcSlash from 'components/Icons/IcSlash';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { Legend, Title } from 'ui';
import { ErrorMessage } from 'ui/forms';
import { ERRORS } from 'utils';
import * as Yup from 'yup';

const NewPassword = () => {
  const navigate = useNavigate();
  const [{ state, userId }] = useSearchParams();
  const queryClient = useQueryClient();

  const isTemporaryPassword = state === 'firstLogin';

  const handleSubmit = async (values, { setStatus }) => {
    try {
      await updatePassword(userId, values);
      queryClient.invalidateQueries(['me']);
      navigate('/');
    } catch (err) {
      const error = err?.response?.data?.message;
      if (error === 'Invalid credentials.') {
        setStatus(ERRORS.INVALID_GRANT);
      }
    }
  };

  const checkLength = value => value?.length >= 8;
  const checkUppercase = value => /[A-Z]/.test(value);
  const checkNumber = value => /\d/.test(value);
  const checkAll = value =>
    checkLength(value) && checkUppercase(value) && checkNumber(value);

  return (
    <AuthLayout>
      <Title as="h1" marginBottom={0.5}>
        Modifier votre mot de passe {isTemporaryPassword && 'provisoire'}
      </Title>
      <Legend marginBottom={1.5}>
        Pour accéder à votre compte personnel veuillez changer le mot de passe
        provisoire.
      </Legend>
      <Formik
        validationSchema={Yup.object().shape({
          password: Yup.string(),
        })}
        initialValues={{ password: '' }}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting, values }) => (
          <Form>
            <Stack direction="column" gutterSize={2}>
              <Stack direction="column" gutterSize={0.5} size="full">
                <TextField
                  name="password"
                  label="Nouveau mot de passe"
                  type="password"
                  autocomplete="password"
                />
                <Stack direction="row" gutterSize={1}>
                  <Stack direction="row" gutterSize={0.2}>
                    {checkLength(values?.password) ? <IcCheck /> : <IcSlash />}
                    <Legend
                      color={checkLength(values?.password) ? 'green' : 'red'}
                    >
                      8 caractères
                    </Legend>
                  </Stack>
                  <Stack direction="row" gutterSize={0.2}>
                    {checkUppercase(values?.password) ? (
                      <IcCheck />
                    ) : (
                      <IcSlash />
                    )}
                    <Legend
                      color={checkUppercase(values?.password) ? 'green' : 'red'}
                    >
                      1 majuscule
                    </Legend>
                  </Stack>
                  <Stack direction="row" gutterSize={0.2}>
                    {checkNumber(values?.password) ? <IcCheck /> : <IcSlash />}
                    <Legend
                      color={checkNumber(values?.password) ? 'green' : 'red'}
                    >
                      1 chiffre
                    </Legend>
                  </Stack>
                </Stack>
              </Stack>
              <Button
                variant="primaryLarge"
                size="full"
                isLoading={isSubmitting}
                disabled={!checkAll(values?.password)}
              >
                Modifier mon mot de passe
              </Button>
              {Boolean(status) && <ErrorMessage>{status}</ErrorMessage>}
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default NewPassword;
