import { memo } from 'react';

const IcInvoice = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.604 2.313a.75.75 0 00-.75.75v17.875c0 .414.335.75.75.75h15.125a.75.75 0 00.75-.75V8.444a.75.75 0 00-.219-.53l-5.383-5.382a.748.748 0 00-.53-.219H4.604zm-2 .75a2 2 0 012-2h9.743c.53 0 1.038.21 1.414.585l5.383 5.383a2 2 0 01.585 1.413v12.494a2 2 0 01-2 2H4.604a2 2 0 01-2-2V3.063z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.99 1.11a.625.625 0 01.68.136l6.876 6.875a.625.625 0 01-.442 1.067h-5.5a2 2 0 01-2-2v-5.5c0-.253.152-.48.386-.577zm.864 2.087v3.991c0 .414.336.75.75.75h3.991l-4.741-4.741zM8.555 5.188c.345 0 .625.28.625.625v.374a2.66 2.66 0 011.578.995.625.625 0 01-.019.78l-1.032 1.23c.203.085.391.191.56.318.46.346.802.87.802 1.502 0 .633-.344 1.157-.804 1.503-.307.23-.68.393-1.085.477v.383a.625.625 0 11-1.25 0v-.374a2.665 2.665 0 01-1.577-.994.625.625 0 01.018-.78l1.032-1.232a2.573 2.573 0 01-.557-.316c-.46-.346-.804-.871-.804-1.504s.344-1.157.804-1.502c.307-.23.68-.394 1.084-.477v-.383c0-.345.28-.625.625-.625zM8.28 8.95l1.125-1.343a1.41 1.41 0 00-.848-.224c-.4 0-.737.122-.961.29-.224.168-.304.352-.304.502 0 .151.08.336.304.504.17.128.406.23.684.27zm.55 1.29l-1.125 1.34a1.417 1.417 0 00.848.225c.4 0 .738-.122.962-.29.224-.168.304-.353.304-.503 0-.151-.08-.335-.303-.502a1.514 1.514 0 00-.687-.27zm2.712 4.51c0-.345.28-.625.625-.625h5.5a.625.625 0 110 1.25h-5.5a.625.625 0 01-.625-.625zm-4.813 4.125c0-.345.28-.625.625-.625h10.313a.625.625 0 110 1.25H7.354a.625.625 0 01-.625-.625z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcInvoice);
