import { memo } from 'react';

const Dashboard = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(-32 -8)"
      d="M42.75 20.438h0c0 .45-.363.816-.813.817h-6.5 0a.813.813 0 01-.812-.816s0 0 0 0v-8.993h0c0-.45.363-.816.813-.817 0 0 0 0 0 0l6.5.01h0c.45.003.813.368.812.817v8.981zm10.622-4.985h0a.808.808 0 01-.813.802h-6.5 0a.806.806 0 01-.812-.8v-4.013h0a.806.806 0 01.81-.802l6.5-.011h0a.81.81 0 01.815.802v4.021zM42.75 28.576h0a.809.809 0 01-.814.804s0 0 0 0l-6.5-.01h0a.809.809 0 01-.811-.804v-4.008h0a.807.807 0 01.812-.803s0 0 0 0h6.5a.808.808 0 01.813.803v4.018zm3.31.804h0a.815.815 0 01-.813-.817s0 0 0 0v-8.99h0a.815.815 0 01.812-.818s0 0 0 0h6.5c.45.001.814.367.813.817 0 0 0 0 0 0v8.98h0c0 .45-.362.815-.812.817l-6.5.011z"
      stroke="currentColor"
      strokeWidth={1.25}
    />
  </svg>
);

export default memo(Dashboard);
