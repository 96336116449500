import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
  ErrorMessageWrapper,
  FormField,
  FormLabel,
  Input,
  InputIcon,
  PasswordButton,
} from 'ui/forms';

const TextField = ({ label, rightLabel, children, ...props }) => {
  const [field, { touched, error }] = useField(props);
  const [id] = useState(uniqueId());

  const isPassword = props.type === 'password';
  const [type, setType] = useState(props.type || 'text');

  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        {rightLabel}
      </Stack>
      <Stack style={{ position: 'relative' }}>
        <Input {...field} {...props} type={type} id={id} hasError={hasError} />
        {Boolean(children) && <InputIcon>{children}</InputIcon>}
        {isPassword && (
          <PasswordButton
            disabled={props.disabled}
            type="button"
            onClick={() => setType(type === 'password' ? 'text' : 'password')}
            tabIndex={-1}
          >
            {type === 'text' ? <MdVisibility /> : <MdVisibilityOff />}
          </PasswordButton>
        )}
        {touched && error && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default TextField;
