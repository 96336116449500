import {
  Box,
  Legend as MgtLegend,
  Subtitle as MgtSubtitle,
  Title as MgtTitle,
  Stack,
} from '@tymate/margaret';
import { Link as RawLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Link = styled(RawLink)`
  ${({ theme }) => theme.fontSize.bodySmall};
  display: block;
  color: ${({ theme }) => theme.colors.blueLight};
`;

export const LinkReset = styled(RawLink)`
  display: block;
  text-decoration: none;
  color: inherit;
`;

export const Title = styled(MgtTitle).attrs({ as: 'h2' })`
  ${({ variant }) =>
    variant === 'upperCase' &&
    `
      text-transform: uppercase;
    `}
`;

export const Subtitle = styled(MgtSubtitle).attrs({ as: 'h3', type: 'h2' })``;

export const Legend = styled(MgtLegend)`
  ${({ variant, theme }) =>
    variant === 'light' &&
    `
      color: ${theme.textLighter};
    `}
`;

export const Hr = styled(Box).attrs({ as: 'hr' })``;

export const PaymentProgress = styled(Stack)`
  ${({ theme }) => theme.fontStyles.headline}

  ${({ variant }) =>
    variant === 'folderCard' &&
    css`
      ${({ theme }) => theme.fontStyles.body};
    `}
`;

export const PaymentProgressTotal = styled.div`
  color: ${({ theme }) => theme.textLighter};
`;

export const PaymentProgressPaid = styled(PaymentProgressTotal)`
  color: ${({ theme }) => theme.text};
  font-weight: 700;
`;

export const Card = styled(Box).attrs(({ variant, padding }) => ({
  variant: variant ?? 'link',
  padding: padding ?? 1,
}))`
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid ${({ theme }) => theme.separator};
  color: inherit;
  text-decoration: none;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }

  ${({ variant }) =>
    variant === 'flat' &&
    css`
      &,
      &:hover {
        box-shadow: none;
      }
    `}
`;
