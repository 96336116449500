import { memo } from 'react';

const Building = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <g
      transform="translate(-32 -8)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M52.25 11.75v19.5m-16.5-19.5h16.5m-16.5 19.5v-19.5m16.5 19.5h-16.5m16.5-19.5h-16.5l1.5-3h13.5zm-19.5 19.5h22.5m-9-2.25h0a2.25 2.25 0 10-4.5 0v2.25h4.5V29zM39.5 14.375h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375 0 0 0 0 0 0m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m0 3.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m0 3.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m4.5-9.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375s0 0 0 0m0 3.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m0 3.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m4.5-9.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m0 3.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375m0 3.75h0c.207 0 .375.168.375.375m-.75 0h0c0-.207.168-.375.375-.375m0 .75h0a.375.375 0 01-.375-.375s0 0 0 0m.75 0h0a.375.375 0 01-.375.375"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);

export default memo(Building);
