import { Container, Stack, media } from '@tymate/margaret';
import styled from 'styled-components';

export const TopBar = styled(Container).attrs({
  as: Stack,
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
})`
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  height: ${({ theme }) => theme.topBar.height};
  position: absolute;
  top: 0;
  right: 0;

  ${media.tablet`
    z-index: 1;
    left: ${({ theme }) => theme.mainNav.width};
    max-width: calc(100% - ${({ theme }) => theme.mainNav.width});
  `}
`;
