import { useMutation } from '@tanstack/react-query';
import { Stack } from '@tymate/margaret';
import { postAttachments } from 'api/import';
import { Button as RawButton } from 'components';
import { IcBin } from 'components/Icons';
import { useField } from 'formik';
import { useApp } from 'hooks';
import { filter, isEqual } from 'lodash';
import styled, { useTheme } from 'styled-components';
import { Legend } from 'ui';
import { ErrorMessageWrapper, FormField, FormLabel } from 'ui/forms';

const ButtonAttachmentPicker = styled(RawButton)`
  ${({ theme }) => theme.fontStacks.body};
  ${({ theme }) => theme.button.simple};
  display: flex;
  cursor: pointer;
  min-height: 2.5rem;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  transition: background 150ms ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.button.simple.boxShadowHover};
  }
`;

const DeleteAttachmentButton = styled(RawButton)`
  justify-content: flex-start;
`;

const AttachmentWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.5,
})`
  max-height: 24px;
`;

const AttachmentsPickerField = ({ label, name, children }) => {
  const { clients } = useApp();
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField({
    name,
  });
  const hasError = Boolean(touched) && Boolean(error);
  const theme = useTheme();
  const postAttachmentsMutation = useMutation(value => postAttachments(value));

  const handleLoadAttachment = async e => {
    setTouched(true);
    try {
      const [file] = e.target.files;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('client_uuid', clients?.[0]?.uuid);
      const { data } = await postAttachmentsMutation.mutateAsync(formData);
      if (value?.length > 0) {
        setValue([...value, { file, id: data?.id }]);
      } else {
        setValue([{ file, id: data?.id }]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading file:', error);
    }
  };

  const handleReset = attachment => {
    setValue(filter(value, v => !isEqual(v, attachment)) || []);
  };

  return (
    <FormField>
      <Stack direction="column" gutterSize={0.25}>
        <FormLabel>{label}</FormLabel>
        <Stack direction="column" alignY="center" gutterSize={0.125}>
          <ButtonAttachmentPicker as="label" variant="simple" type="button">
            <input
              type="file"
              accept="image/*"
              onChange={handleLoadAttachment}
              onClick={e => {
                e.target.value = null;
              }}
              style={{ display: 'none' }}
            />
            {children}
          </ButtonAttachmentPicker>

          {value?.length > 0 &&
            value?.map(attachment => (
              <AttachmentWrapper key={attachment?.id}>
                <div>
                  <Legend>{attachment?.file?.name}</Legend>
                </div>
                <DeleteAttachmentButton
                  type="button"
                  onClick={() => handleReset(attachment)}
                  icon={<IcBin size={18} color={theme.textLighter} />}
                />
              </AttachmentWrapper>
            ))}

          {postAttachmentsMutation.isLoading && (
            <AttachmentWrapper>
              <div>
                <Legend>Chargement de la pièce jointe…</Legend>
              </div>
            </AttachmentWrapper>
          )}
        </Stack>
        {hasError && (
          <ErrorMessageWrapper style={{ transform: 'translateY(-4px)' }}>
            {error}
          </ErrorMessageWrapper>
        )}
      </Stack>
    </FormField>
  );
};

export default AttachmentsPickerField;
