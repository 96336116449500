import styled from 'styled-components';

export const Tag = styled.div`
  width: auto;
  height: 24px;
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  display: inline-block;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.backgroundTag};
  text-align: center;
  color: ${({ theme }) => theme.colors.blueLight};
  font-size: 14px;
`;

export const RoundedTag = styled.div`
  width: auto;
  display: inline-block;
  height: 24px;
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  background-color: ${({ theme }) => theme.backgroundTag};
  line-height: 1.43;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.primaryDark};
`;
