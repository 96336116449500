import { memo } from 'react';

const IcSynchronize = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.132 4.688c.85 0 1.549.699 1.549 1.562v4.688a.521.521 0 101.042 0V6.25a2.602 2.602 0 00-2.607-2.604H7.397a.521.521 0 100 1.042H13.132zM4.784 8.332c.288 0 .521.233.521.521v4.688a1.57 1.57 0 001.564 1.563h5.736a.521.521 0 010 1.042H6.864a2.611 2.611 0 01-2.601-2.605V8.853c0-.288.233-.521.521-.521z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.237 7.964a.521.521 0 01.736 0l2.236 2.235 2.236-2.235a.521.521 0 11.736.736l-2.604 2.604a.52.52 0 01-.736 0L12.237 8.7a.52.52 0 010-.736zm-7.445.367c.138 0 .27.055.368.153l2.604 2.605a.521.521 0 01-.736.736L4.792 9.59l-2.236 2.236a.521.521 0 01-.736-.736l2.604-2.605a.521.521 0 01.368-.153z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcSynchronize);
