import { memo } from 'react';

const IcEuro = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.606 8.802a8.142 8.142 0 000 2.395h3.401a.521.521 0 010 1.042H7.833a8.075 8.075 0 007.757 5.833.521.521 0 010 1.042 9.117 9.117 0 01-8.837-6.875H4.132a.521.521 0 110-1.042h2.422a9.2 9.2 0 010-2.395H4.132a.521.521 0 010-1.042h2.621A9.117 9.117 0 0115.59.885a.521.521 0 010 1.042A8.075 8.075 0 007.833 7.76h5.466a.521.521 0 010 1.042H7.606z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcEuro);
