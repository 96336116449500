import jwtDecode from 'jwt-decode';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const getUserName = token => {
  try {
    return jwtDecode(token)?.username;
  } catch (err) {}
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken'),
  );
  const username = getUserName(accessToken);

  const handleReceiveToken = ({ token }) => {
    localStorage.setItem('accessToken', token);
    setAccessToken(token);
    navigate('/');
  };

  const handleReset = () => {
    localStorage.clear();
    setAccessToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(accessToken),
        logout: handleReset,
        onReceiveToken: handleReceiveToken,
        accessToken,
        username,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
