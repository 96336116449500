import { isEmpty, pickBy } from 'lodash';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const useSearchParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let searchParams = {};

  const setSearchParams = params => {
    const toLocation =
      location.pathname +
      (!isEmpty(pickBy(params))
        ? `?${queryString.stringify(pickBy(params))}`
        : '');
    navigate(toLocation);
  };

  try {
    searchParams = queryString.parse(location.search.replace('?', ''));
  } catch (err) {}

  return [searchParams, setSearchParams];
};

export default useSearchParams;
